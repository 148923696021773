
import {computed, defineComponent, ref} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import miceSectorList from "@/core/data/micesector";
import AutoComplete from "@/components/appcomps/forms/AutoComplete.vue"
import MasterConfig from "@/core/config/MasterConfig";

import Tagify from '@yaireo/tagify';

import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr";
import {dateConvert} from "@/core/filters/datatime";

interface ReqData {
    reqID: number;
    reqTitle: string;
    reqDate: string;
    reqSNG: string;
    reqDBL: string;
    reqTRP: string;
    reqPAX: string;
    reqSMPerson: string;
    reqSector: string;
    reqAccount: string;
    reqHotels: string;
    reqDatesCIN: Array<any>;
    reqDatesCOUT: Array<any>;
    reqNotes: string;
}

export default defineComponent({
    name: "mice-request-update",
    components: {
        ErrorMessage, Field, Form,
        AutoComplete,
    },
    methods: {
        setDetail(prmDetail, prmAccountDetail, prmTheDates, prmArrHotels) {
            console.log("RequestUpdate setDetail START");
            this.arrHotels = prmArrHotels;

            this.reqData.reqID = prmDetail.ID;
            this.reqData.reqTitle = prmDetail.Title;
            this.reqData.reqDate = prmDetail.RequestDate;
            this.reqData.reqNotes = prmDetail.Notes;
            this.reqData.reqSNG = prmDetail.RoomSNG;
            this.reqData.reqDBL = prmDetail.RoomDBL;
            this.reqData.reqTRP = prmDetail.RoomTRP;
            this.reqData.reqPAX = prmDetail.PAX;
            this.reqData.reqSector = prmDetail.Sector;
            this.reqData.reqSMPerson = prmDetail.SMPerson;
            this.reqData.reqAccount = prmDetail.AccountID;
            this.reqData.reqHotels = prmDetail.reqHotels;


            this.refAutoComplete_Accounts.setInput(prmAccountDetail.Title);
            this.setPickerDates(new Date());

            //Dates
            this.ccvDateCount = prmTheDates.CIN.length;
            for (let i=0; i<prmTheDates.CIN.length; i++) {
                let vvDateCIN =  new Date(prmTheDates.CIN[i]);
                this.reqData.reqDatesCIN.push(dateConvert.method.convertToDateFormated(vvDateCIN, "DD-MM-YYYY"));
                let vvDateCOUT =  new Date(prmTheDates.COUT[i]);
                this.reqData.reqDatesCOUT.push(dateConvert.method.convertToDateFormated(vvDateCOUT, "DD-MM-YYYY"));
                //console.log("RequestUpdate setDetail each CIN:  "+prmTheDates.CIN[i]+" vvDateCIN: "+vvDateCIN+" "+dateConvert.method.convertToDateFormated(vvDateCIN, "DD-MM-YYYY"));
            }


            //(document.getElementById("txt_Mice_RequestUpdate_Hotels") as HTMLInputElement).value="ROCKS HOTEL & CASINO";

            console.log("setDetail prmDetail: "+JSON.stringify(prmDetail));
            //console.log("setDetail prmTheDates: "+JSON.stringify(prmTheDates));

            console.log("setDetail prmArrHotels: "+JSON.stringify(prmArrHotels));

            //ROCKS HOTEL & CASINO
            //tagifyHotels.addTags( [{"value":"ROCKS HOTEL & CASINO", "title": "ROCKS HOTEL & CASINO", "type": "account", "ID":6}]);
            //this.setTagifyHotelValues(prmArrHotels);

            store.dispatch(Actions.HOTEL_LIST_ALL, {});

        },
    },
    setup() {

        const refBtnSubmit = ref<null | HTMLButtonElement>(null);
        const refModal = ref<null | HTMLElement>(null);
        const refAutoComplete_Accounts =  ref(null);
        const refAutoComplete_Accounts2 =  ref(null);

        const arrHotels = ref([]);

        var ccvDateCount = ref(1);

        const reqData = ref<ReqData>({
            reqID: 0,
            reqTitle: "",
            reqDate: "",
            reqSNG: "",
            reqDBL: "",
            reqTRP: "",
            reqPAX: "",
            reqSMPerson: "",
            reqSector: "",
            reqAccount: "",
            reqHotels: "",
            reqNotes: "",
            reqDatesCIN: [],
            reqDatesCOUT: [],
        });

        function setPickerDates(prmDefaultDate){
            var minDate = new Date();
            minDate.setDate(minDate.getDate()-MasterConfig.mice.requestAddDay);
            let config = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                minDate: minDate,
                maxDate:'today',
                defaultDate: prmDefaultDate,
            };
            this.startpicker = flatpickr("#inp_Mice_RequestUpdate_ReqDate", config);
        }

        const validationSchema = Yup.object().shape({
            reqTitle: Yup.string().required().label("Fiyat Listesi İsmi"),
        });


        // AutoSelect Accounts ***** BEGIN *******************
        let acAccountsKeyword = "";

        function keyupAutoCompAccounts(prmInputValue) {
            console.log("keyupAutoCompAccounts prmInputValue: "+prmInputValue+" len: "+prmInputValue.length+" acAccountsKeyword: "+acAccountsKeyword);
            if (prmInputValue.length == 3 && acAccountsKeyword != prmInputValue) {
                acAccountsKeyword = prmInputValue;
                if (prmInputValue.length == 3) {
                    store.dispatch(Actions.ACCOUNT_LIST_ALL, {'Title': prmInputValue, 'CallFrom': 'RequestUpdate_Accounts'});
                }

                //masterValue.value.resAccount = "";
            }
        }

        function itemProjectionAccounts(prmItem) {
            return prmItem.name;
        }

        function selectItemAutoCompAccounts(prmItem) {
            //masterValue.value.resAccount = prmItem.id;
            let payload = {AccountID: prmItem.id, is_active: "active"};
        }

        const lstAccounts = computed(() => {
            let dataComputed = store.getters.accountList;
            console.log("lstAccounts dataComputed: " + JSON.stringify(dataComputed));
            return dataComputed;
        });

        const lstAutoCompleteItemsAccounts = computed(() => {
            let arrTmp = [];
            if (lstAccounts.value !== undefined && lstAccounts.value.data !== undefined) {
                for (let each of lstAccounts.value.data) {
                    arrTmp.push({"id": each.AccountID, "name": each.Title});
                }
            }
            //console.log("arrTmp");
            //console.log(arrTmp);

            return arrTmp;
        });
        // AutoSelect Accounts ***** END *******************

        // AutoSelect Accounts2 ***** BEGIN *******************
        let acAccountsKeyword2 = "";

        function keyupAutoCompAccounts2(prmInputValue) {
            console.log("keyupAutoCompAccounts prmInputValue: "+prmInputValue+" len: "+prmInputValue.length+" acAccountsKeyword: "+acAccountsKeyword2);
            if (prmInputValue.length == 3 && acAccountsKeyword2 != prmInputValue) {
                acAccountsKeyword2 = prmInputValue;
                if (prmInputValue.length == 3) {
                    store.dispatch(Actions.ACCOUNT_LIST_ALL, {'Title': prmInputValue, 'CallFrom': 'RequestUpdate_Accounts2'});
                }

                //masterValue.value.resAccount = "";
            }
        }

        function itemProjectionAccounts2(prmItem) {
            return prmItem.name;
        }

        function selectItemAutoCompAccounts2(prmItem) {
            //masterValue.value.resAccount = prmItem.id;
            let payload = {AccountID: prmItem.id, is_active: "active"};
        }

        const lstAccounts2 = computed(() => {
            let dataComputed = store.getters.accountList;
            console.log("lstAccounts2 dataComputed: " + JSON.stringify(dataComputed));
            if (dataComputed.CallFrom!==undefined) {
                if (dataComputed.CallFrom==="RequestUpdate_Accounts2") {
                    return dataComputed;
                }
            }
            return [];
        });

        const lstAutoCompleteItemsAccounts2 = computed(() => {
            let arrTmp = [];
            if (lstAccounts2.value !== undefined && lstAccounts2.value.data !== undefined) {
                for (let each of lstAccounts2.value.data) {
                    arrTmp.push({"id": each.AccountID, "name": each.Title});
                }
            }
            //console.log("arrTmp");
            //console.log(arrTmp);

            return arrTmp;
        });
        // AutoSelect Accounts2 ***** END *******************


        // Tagify Hotels **** START ********************




        const myHotel = computed(() => {
            return store.getters.hotelList;
        });

        const myTagsHotel = computed(() => {
            if (myHotel.value.data) {
                let tmpList = [];
                for (let i = 0; i < myHotel.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myHotel.value.data[i].Title).trim(),
                        title: (myHotel.value.data[i].Title).trim(),
                        type: 'account',
                        ID: myHotel.value.data[i].ID,
                    };

                }
                setTagInput(tmpList);
                return tmpList;

            } else {
                return [];
            }
        });

        let tagifyHotels;
        const setTagInput = (wList) => {


            let input = document.querySelector('input[name=Mice_RequestUpdate_Hotels]');


            let arrTags = getTagifyValuesForHotels(arrHotels.value);
            if (arrTags.length>0) {
                // init Tagify script on the above inputs
                tagifyHotels = new Tagify(input, {
                    tagTextProp: 'value', // very important since a custom template is used with this property as text
                    enforceWhitelist: true,
                    duplicates: false,
                    skipInvalid: true, // do not remporarily add invalid tags
                    whitelist: wList,
                    dropdown: {
                        searchKeys: ["value"],
                        maxItems: 10,
                        closeOnSelect: false,
                        position: "text",
                        enabled: 0, // always opens dropdown when input gets focus
                    },
                });
                tagifyHotels.addTags( arrTags);
                console.log("Tags Inited");
            }





        }


        function getTagifyValuesForHotels(prmArr) {
            console.log("setTagifyValues START: " + JSON.stringify(prmArr));
            let arrTags = [];
            for (let i=0; i<prmArr.length; i++) {
                console.log("setTagifyValues each: " + i);
                let vvEach =  arrHotels.value[i];
                let objTag = {"value":vvEach.value, "title": vvEach.value, "type": "account", "ID":Number(vvEach.ID)};
                arrTags.push(objTag);

                //tagifyHotels.addTags( [{"value":"ROCKS HOTEL & CASINO", "title": "ROCKS HOTEL & CASINO", "type": "account", "ID":6}]);
            }

            return arrTags;
        }

        function onHotelChange(e) {
            if(e.target.value.length>0) {
                reqData.value.reqHotels = e.target.value;
            }else{
                reqData.value.reqHotels = '';
            }
        }

        // Tagify Hotels **** END ********************

        const submitForm = () => {
            console.log("submitForm START");

            if (!refBtnSubmit.value) {
                return;
            }

            let myDates = {
                CIN: reqData.value.reqDatesCIN.map(dateConvert.method.convertToDateYM),
                COUT: reqData.value.reqDatesCOUT.map(dateConvert.method.convertToDateYM)
            };

            const payload1 = {
                ID: reqData.value.reqID,
                Title: reqData.value.reqTitle,
                RoomSNG: reqData.value.reqSNG,
                RoomDBL: reqData.value.reqDBL,
                RoomTRP: reqData.value.reqTRP,
                PAX: reqData.value.reqPAX,
                Notes: reqData.value.reqNotes,
                Sector: reqData.value.reqSector,
                SMPerson:  reqData.value.reqSMPerson,
                Hotels: reqData.value.reqHotels,
                Dates: JSON.stringify(myDates),
            }

            console.log("submitForm v1 payload1: "+JSON.stringify(payload1));

            refBtnSubmit.value.disabled = true;
            refBtnSubmit.value.setAttribute("data-kt-indicator", "on");

            store.dispatch(Actions.MICE_REQUESTS_EDIT, payload1)
                .then(() => {
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                        icon: "success", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                        confirmButtonText: "Geri Dön!",
                    }).then(function () {
                        refBtnSubmit.value.disabled = false;
                        refBtnSubmit.value.setAttribute("data-kt-indicator", "off");
                        store.dispatch(Actions.MICE_REQUESTS_DETAILS, {ID: reqData.value.reqID});
                        hideModal(refModal.value);
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getMiceRequestsErrors[0],
                        icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                        confirmButtonText: "Tekrar dene!",
                    });
                    refBtnSubmit.value.disabled = false;
                    refBtnSubmit.value.setAttribute("data-kt-indicator", "off");
            });

        }

        return {
            refModal, refBtnSubmit,
            validationSchema, submitForm,
            reqData,
            miceSectorList,
            MasterConfig,
            setPickerDates,
            refAutoComplete_Accounts, keyupAutoCompAccounts, itemProjectionAccounts, selectItemAutoCompAccounts, lstAutoCompleteItemsAccounts,
            refAutoComplete_Accounts2, keyupAutoCompAccounts2, itemProjectionAccounts2, selectItemAutoCompAccounts2, lstAutoCompleteItemsAccounts2,
            onHotelChange, myTagsHotel,
            ccvDateCount,
            arrHotels,
        };
    },
});

