const miceSectorList = {
  "TYPES" : [
    {
      "Value" : 'OTOMOTIV',
      "Title" : 'OTOMOTIV',
    },
    {
      "Value" : 'ECZANE / ECZA DEPOSU',
      "Title" : 'ECZA',
    },
    {
      "Value" : 'MEDIKAL',
      "Title" : 'MEDIKAL',
    },
    {
      "Value" : 'İLAÇ',
      "Title" : 'ILAC',
    },
    {
      "Value" : 'AKÜ',
      "Title" : 'AKU',
    },
    {
      "Value" : 'DENTAL',
      "Title" : 'DENTAL',
    }],
};
export default miceSectorList;
