
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";
import RequestsUpdate from "@/views/apps/sys/mice/requests/RequestUpdate.vue";

import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";

export default defineComponent({
    name: "mice-request-detail",
    components: {
        RequestsUpdate,

    },
    setup() {

        const route = useRoute();
        const paramId = route.params.id;

        const refRequestUpdate = ref(null);

        const theAccountDetail = ref({});

        onMounted(()=>{
            setCurrentPageBreadcrumbs("Talep Detayı", ["MICE", "Talepler"]);
            store.dispatch(Actions.MICE_REQUESTS_DETAILS, {ID: paramId});

            store.dispatch(Actions.HOTEL_LIST_ALL, {});

        });

        const theDetail = computed(() => {
            let dataComputed =store.getters.miceRequestsDetails;
            if (dataComputed.Hotels!==undefined) {
                setObjects(JSON.parse(dataComputed.Dates), JSON.parse(dataComputed.Hotels));
                getAccountName(dataComputed.AccountID);
            }
            return dataComputed;
        });

        const theDates = ref([]);
        const arrHotels = ref([]);

        function setObjects(prmDates, prmHotels) {
            theDates.value = prmDates;
            arrHotels.value = prmHotels;
        }

        function getAccountName(prmAccountID) {
            store.dispatch(Actions.ACCOUNT_LIST_ALL, {'ID':prmAccountID});
        }

        function setAccountDetail(prmData) {
            console.log("setAccountDetail "+JSON.stringify(prmData));
            if (prmData!==undefined && prmData.data!==undefined && prmData.data.length>0) {
                theAccountDetail.value = prmData.data[0];
            }

        }

        const dataAccountList = computed(() => {
            let dataComputed = store.getters.accountList;
            setAccountDetail(dataComputed);
            return dataComputed;
        });

        function click_UpdateDetail(prmDetail) {
            refRequestUpdate.value.setDetail(prmDetail, theAccountDetail.value, theDates.value, arrHotels.value );
        }

        return {
            refRequestUpdate,
            theDetail, arrHotels, theDates,
            click_UpdateDetail,
            dataAccountList, theAccountDetail,

        };
    },
});
