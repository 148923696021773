
import {defineComponent, ref} from "vue";
export default defineComponent({
    props: {idCode: String, placeHolder:String, headerTitle:String, items: {}, itemProjection:Function},

    mounted() {
        this.clearInput();
    },
    methods: {
        clearInput() {
            //console.log("clearInput AutoComplete START");
            //console.log((this.$refs.SimpleTypeHead01 as any).boldMatchText("Hello"));
            //(this.$refs.SimpleTypeHead01 as any).clearInput();
            //(this.$refs.SimpleTypeHead01 as any).data.input;
            this.SimpleTypeHead01.input = "";
            //console.log("input: "+this.SimpleTypeHead01.input);
        },
        setInput(prmInput) {
            this.SimpleTypeHead01.input = prmInput;
        }
    },

    setup(props, ctx) {

        const SimpleTypeHead01 = ref(null);

        function keyupAutoComp() {
            let elAutoComp = document.getElementById("inpAutoComp_"+props.idCode) as HTMLInputElement;
            if (elAutoComp!==undefined && elAutoComp!==null) {
                //console.log("keyupAutoComp elAutoComp: "+elAutoComp.value);
                ctx.emit('keyupAutoComp', elAutoComp.value);
            }
        }

        function selectItemAutoComp(prmItem) {
            //console.log("selectItemAutoComp id: "+prmItem.id);
            ctx.emit('selectItem', prmItem);
        }

        return {keyupAutoComp, selectItemAutoComp, SimpleTypeHead01};
    },
});

